import * as R from 'ramda';
import { $set } from 'plow-js';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { reducer as toastr } from 'react-redux-toastr';
import { routerReducer as router } from 'react-router-redux';
// common
import { ACTION_TYPE_CLEAN_FORM_REDUCER } from './common/actions';
// components
import modal from './components/modal/reducer';
import loader from './components/loader/reducer';
import orderTable from './common/idb/order/reducer';
import audio from './components/audio-player/reducer';
import resizeTable from './common/idb/resize/reducer';
// features
import auth from './features/auth/reducer';
import user from './features/user/reducer';
import menu from './features/menu/reducer';
import intl from './features/intl/reducer';
import roles from './features/role/reducer';
import audit from './features/audit/reducer';
import icons from './features/icons/reducer';
import newDO from './features/new-do/reducer';
import audit2 from './features/audit2/reducer';
import quotes from './features/quotes/reducer';
import charts from './features/charts/reducer';
import branch from './features/branch/reducer';
import locale from './features/locale/reducer';
import styling from './features/styling/reducer';
import carrier from './features/carrier/reducer';
import payrolls from './features/payroll/reducer';
import imports from './features/new-import/reducer';
import rateShop from './features/rate-shop/reducer';
import fleetMap from './features/fleet-map/reducer';
import sequences from './features/sequence/reducer';
import reference from './features/reference/reducer';
import analytics from './features/dashboard/reducer';
import publicCLO from './features/public-clo/reducer';
import fuelCards from './features/fuel-cards/reducer';
import fleetList from './features/fleet-list/reducer';
import userSettings from './features/profile/reducer';
import loadBoard from './features/load-board/reducer';
import liteNewDO from './features/lite-new-do/reducer';
import reports from './features/report-format/reducer';
import config from './features/configurations/reducer';
import dashboards from './features/dashboards/reducer';
import pivotTable from './features/pivot-table/reducer';
import carrierEdi from './features/carrier-edi/reducer';
import fleetTruck from './features/fleet/truck/reducer';
import appRelease from './features/app-release/reducer';
import permissions from './features/permission/reducer';
import iftaReport from './features/ifta-report/reducer';
import navbarMenu from './features/navbar-menu/reducer';
import newDOQuote from './features/new-do-quote/reducer';
import fleetVendor from './features/fleet/vendor/reducer';
import driversCard from './features/drivers-card/reduces';
import mailSending from './features/mail-sending/reducer';
import fleetDriver from './features/fleet/driver/reducer';
import tollCharges from './features/toll-charges/reducer';
import chargeReport from './features/charge-report/reducer';
import fleetTrailer from './features/fleet/trailer/reducer';
import importReport from './features/import-report/reducer';
import notifications from './features/notification/reducer';
import dispatchGroup from './features/dispatch-group/reducer';
import carrierPortal from './features/carrier-portal/reducer';
import serviceVendor from './features/service-vendor/reducer';
import vendorInvoices from './features/invoice/vendor/reducer';
import ediIntegration from './features/edi-integration/reducer';
import customerPortal from './features/customer-portal/reducer';
import templateReport from './features/template/report/reducer';
import carrierInvoice from './features/invoice/carrier/reducer';
import taskManagement from './features/task-management/reducer';
import claimManagement from './features/claim-management/reducer';
import loadReport from './features/dispatch-report/load/reducer';
import componentsStory from './features/components-story/reducer';
import losSubscription from './features/los-subscription/reducer';
import availableDriver from './features/available-driver/reducer';
import customerInvoice from './features/invoice/customer/reducer';
import dispatchPlanner from './features/dispatch-planner/reducer';
import dispatchBoard from './features/dispatch-board-new/reducer';
import routeReport from './features/dispatch-report/route/reducer';
import orderReport from './features/dispatch-report/order/reducer';
import carrierContracts from './features/contracts/carrier/reducer';
import averageFuelPrice from './features/average-fuel-price/reducer';
import carrierInsurances from './features/carrier/insurances/reducer';
import dashboardsPayroll from './features/dashboards-payroll/reducer';
import expandedContainer from './features/expanded-container/reducer';
import customerContracts from './features/contracts/customer/reducer';
import customerMasterInvoice from './features/master-invoice/reducer';
import driverPayrollInvoices from './features/invoice/driver/reducer';
import loadDetails from './features/dispatch-details-new/load/reducer';
import vendorPayrolls from './features/payroll/vendor-payroll/reducer';
import containerTypes from './features/template/container-type/reducer';
import orderDetails from './features/dispatch-details-new/order/reducer';
import dispatchBoardTel from './features/dispatch-board-new/load/reducer';
import loadPlannerTemplate from './features/load-planner-template/reducer';
import driverProfileSimple from './features/driver-profile-simple/reducer';
import normalizeCurrency from './features/normalize-currency-list/reducer';
import dispatchBoardClo from './features/dispatch-board-new/order/reducer';
import carrierRatingReport from './features/carrier-rating-report/reducer';
import commissionAssignment from './features/commission-assignment/reducer';
import telEvents from './features/dispatch-report/load/load-events/reducer';
import serviceVendorInvoice from './features/invoice/service-vendor/reducer';
import telByClo from './features/dispatch-report/load/load-by-order/reducer';
import ratingGeoFencingZone from './features/geo-fencing-zone/rating/reducer';
import reportGeoFencingZone from './features/geo-fencing-zone/report/reducer';
import dispatchPlannerEvents from './features/dispatch-planner-events/reducer';
import carrierRateRequest from './features/carrier-rate-request-report/reducer';
import routeMassCreateReport from './features/route-mass-create-report/reducer';
import routeByLoad from './features/dispatch-report/route/route-by-load/reducer';
import carrierStatisticReport from './features/carrier-statistic-report/reducer';
// forms
import templates from './forms/forms/template-search/reducer';
// fleet-profile
import fleetProfile from './features/fleet-profile/reducer';
// carrier-profile
import carrierProfile from './features/carrier-profile/reducer';
// helpers/constants
import global from './common/reducer';
//////////////////////////////////////////////////

const appReducer = combineReducers({
  form,
  auth,
  intl,
  menu,
  user,
  icons,
  newDO,
  audio,
  roles,
  audit,
  audit2,
  modal,
  router,
  branch,
  toastr,
  charts,
  global,
  loader,
  config,
  locale,
  quotes,
  styling,
  carrier,
  reports,
  imports,
  telByClo,
  payrolls,
  rateShop,
  fleetMap,
  telEvents,
  liteNewDO,
  publicCLO,
  reference,
  fuelCards,
  templates,
  analytics,
  sequences,
  loadBoard,
  fleetList,
  dashboards,
  navbarMenu,
  carrierEdi,
  newDOQuote,
  appRelease,
  fleetTruck,
  loadReport,
  pivotTable,
  iftaReport,
  orderTable,
  resizeTable,
  routeReport,
  tollCharges,
  mailSending,
  orderReport,
  loadDetails,
  fleetDriver,
  permissions,
  fleetVendor,
  driversCard,
  routeByLoad,
  chargeReport,
  importReport,
  orderDetails,
  fleetTrailer,
  userSettings,
  carrierPortal,
  notifications,
  dispatchBoard,
  dispatchGroup,
  serviceVendor,
  carrierProfile,
  taskManagement,
  containerTypes,
  customerPortal,
  carrierInvoice,
  vendorInvoices,
  ediIntegration,
  vendorPayrolls,
  templateReport,
  availableDriver,
  customerInvoice,
  dispatchPlanner,
  claimManagement,
  losSubscription,
  componentsStory,
  averageFuelPrice,
  dispatchBoardTel,
  dispatchBoardClo,
  carrierContracts,
  carrierInsurances,
  customerContracts,
  dashboardsPayroll,
  expandedContainer,
  normalizeCurrency,
  carrierRateRequest,
  loadPlannerTemplate,
  driverProfileSimple,
  carrierRatingReport,
  commissionAssignment,
  serviceVendorInvoice,
  ratingGeoFencingZone,
  reportGeoFencingZone,
  customerMasterInvoice,
  driverPayrollInvoices,
  dispatchPlannerEvents,
  routeMassCreateReport,
  carrierStatisticReport,
  // fleet-profile
  fleetProfile,
});

const rootReducer = (state: Object, action: Object) => {
  if (R.equals(action.type, ACTION_TYPE_CLEAN_FORM_REDUCER)) {
    const newState = $set('form', {}, state);

    return appReducer(newState, action);
  }

  return appReducer(state, action);
};

export default rootReducer;

export { appReducer };
