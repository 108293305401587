import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import ScriptTag from 'react-script-tag';
import React, { lazy, Suspense } from 'react';
import { createStructuredSelector } from 'reselect';
// common
import { makeSelectGoogleApiKey } from './common/selectors';
// helpers/constants
import * as G from './helpers';
import * as GC from './constants';
// ui
import { Box, ListWrapper } from './ui';
// features
import Login from './features/auth/login';
import OAuthPopup from './features/oauth-popup';
import NALLogin from './features/auth/nal-login';
import MainLayout from './components/main-layout';
import NewPassword from './features/auth/new-password';
import ResetPassword from './features/auth/reset-password';
import CarrierPortalComponent from './features/carrier-portal';
import BoshLosRedirect from './features/auth/bosh-los-redirect';
import BoshLosCallback from './features/auth/bosh-los-callback';
import NewPasswordMobile from './features/auth/new-password-mobile';
import PrivacyPolicy from './features/auth/legal-info/privacy-policy';
import CarrierResponsePage from './features/carrier-portal/response-page';
import CarrierResponseAccept from './features/carrier-portal/carrier-accept';
import CarrierResponseDecline from './features/carrier-portal/carrier-decline';
import TermsAndConditions from './features/auth/legal-info/terms-and-conditions';
import CarrierContractDetails from './features/carrier-profile/contracts/carrier';
import NewPasswordMobileSuccessPage from './features/auth/new-password-mobile-success-page';
//////////////////////////////////////////////////

// general
const Imports = lazy(() => import('./features/new-import'));
const IntlComponent = lazy(() => import('./features/intl'));
const LoadBoard = lazy(() => import('./features/load-board'));
const DashboardComponent = lazy(() => import('./features/dashboard'));
const LiteNewDOComponent = lazy(() => import('./features/lite-new-do'));
const NewDOQuoteComponent = lazy(() => import('./features/new-do-quote'));
const DispatchPlanner = lazy(() => import('./features/dispatch-planner'));
const DispatchGroupList = lazy(() => import('./features/dispatch-group'));
const DriversCardComponent = lazy(() => import('./features/drivers-card'));
const LosSubscriptionList = lazy(() => import('./features/los-subscription'));
const AvailableDriverList = lazy(() => import('./features/available-driver'));
const NotificationListComponent = lazy(() => import('./features/notification'));
const DashboardsPayrollComponent = lazy(() => import('./features/dashboards-payroll'));
const DispatchPlannerEvents = lazy(() => import('./features/dispatch-planner-events'));
// dispatch
const PublicCLOList = lazy(() => import('./features/public-clo'));
const CarrierEdiList = lazy(() => import('./features/carrier-edi'));
const QuotesListComponent = lazy(() => import('./features/quotes'));
const EDIIntegrationReport = lazy(() => import('./features/edi-integration'));
const RouteListLoad = lazy(() => import('./features/dispatch-board-new/load'));
const RouteListOrder = lazy(() => import('./features/dispatch-board-new/order'));
const LoadDetailsNewComponent = lazy(() => import('./features/dispatch-details-new/load'));
const OrderDetailsNewComponent = lazy(() => import('./features/dispatch-details-new/order'));
// invoice
const DriverPayrollsComponent = lazy(() => import('./features/payroll'));
const DriverInvoiceList = lazy(() => import('./features/invoice/driver'));
const VendorInvoiceList = lazy(() => import('./features/invoice/vendor'));
const CarrierInvoiceList = lazy(() => import('./features/invoice/carrier'));
const CustomerInvoiceList = lazy(() => import('./features/invoice/customer'));
const CustomerMasterInvoicesComponent = lazy(() => import('./features/master-invoice'));
const ServiceVendorInvoiceList = lazy(() => import('./features/invoice/service-vendor'));
// contracts
const CarrierContractList = lazy(() => import('./features/contracts/carrier'));
const CustomerContractList = lazy(() => import('./features/contracts/customer'));
// templates
const TemplateReport = lazy(() => import('./features/template/report'));
const LoadPlannerTemplateList = lazy(() => import('./features/load-planner-template'));
const CommissionAssignmentList = lazy(() => import('./features/commission-assignment'));
const ContainerTypesComponent = lazy(() => import('./features/template/container-type'));
const RatingGeoFencingZoneList = lazy(() => import('./features/geo-fencing-zone/rating'));
const ReportGeoFencingZoneList = lazy(() => import('./features/geo-fencing-zone/report'));
// views
const BranchList = lazy(() => import('./features/branch'));
const UserComponent = lazy(() => import('./features/user'));
const RolesComponent = lazy(() => import('./features/role'));
const ReportsList = lazy(() => import('./features/report-format'));
const ChargeReport = lazy(() => import('./features/charge-report'));
const ChartsListComponent = lazy(() => import('./features/charts'));
const CarriersListComponent = lazy(() => import('./features/carrier'));
const UserSettingsComponent = lazy(() => import('./features/profile'));
const CarrierProfile = lazy(() => import('./features/carrier-profile'));
const SequencesListComponent = lazy(() => import('./features/sequence'));
const ServiceVendorList = lazy(() => import('./features/service-vendor'));
const ReferenceListComponent = lazy(() => import('./features/reference'));
const LoadListComponent = lazy(() => import('./features/dispatch-report/load'));
const AverageFuelPriceList = lazy(() => import('./features/average-fuel-price'));
const EditBranch = lazy(() => import('./features/branch/components/edit-branch'));
const OrderListComponent = lazy(() => import('./features/dispatch-report/order'));
const RouteListComponent = lazy(() => import('./features/dispatch-report/route'));
const EditRoleComponent = lazy(() => import('./features/role/components/edit-role'));
const NormalizeCurrencyList = lazy(() => import('./features/normalize-currency-list'));
const ChartFormComponent = lazy(() => import('./features/charts/components/chart-form'));
const CreateRoleComponent = lazy(() => import('./features/role/components/create-role'));
const CarrierEditComponent = lazy(() => import('./features/carrier/components/edit-form'));
const ServiceVendorProfile = lazy(() => import('./features/service-vendor/components/edit-form'));
// background processes
const RateShop = lazy(() => import('./features/rate-shop'));
const ImportReport = lazy(() => import('./features/import-report'));
const CarrierRatingReportList = lazy(() => import('./features/carrier-rating-report'));
const RouteMassCreateReport = lazy(() => import('./features/route-mass-create-report'));
// fleet
const FleetMap = lazy(() => import('./features/fleet-map'));
const FleetList = lazy(() => import('./features/fleet-list'));
const IftaListComponent = lazy(() => import('./features/ifta-report'));
const FuelCardListComponent = lazy(() => import('./features/fuel-cards'));
const TollChargesListComponent = lazy(() => import('./features/toll-charges'));
const FleetProfileTruck = lazy(() => import('./features/fleet-profile/truck'));
const FleetProfileVendor = lazy(() => import('./features/fleet-profile/vendor'));
const FleetProfileDriver = lazy(() => import('./features/fleet-profile/driver'));
const FleetProfileTrailer = lazy(() => import('./features/fleet-profile/trailer'));
const EditTruck = lazy(() => import('./features/fleet/truck/components/edit-form'));
const EditDriver = lazy(() => import('./features/fleet/driver/components/edit-form'));
const TrailerEdit = lazy(() => import('./features/fleet/trailer/components/edit-form'));
const EditFleetVendor = lazy(() => import('./features/fleet/vendor/components/edit-form'));
// configs
const StylingComponent = lazy(() => import('./features/styling'));
const ConfigUIComponent = lazy(() => import('./features/configurations/config-pages/ui'));
const ConfigAuditPage = lazy(() => import('./features/configurations/config-pages/audit'));
const ConfigTelComponent = lazy(() => import('./features/configurations/config-pages/tel'));
const ConfigCloComponent = lazy(() => import('./features/configurations/config-pages/clo'));
const SplashScreenComponent = lazy(() => import('./features/styling/components/splash-screen'));
const ConfigTruckComponent = lazy(() => import('./features/configurations/config-pages/truck'));
const ConfigDriverComponent = lazy(() => import('./features/configurations/config-pages/driver'));
const ConfigRatingComponent = lazy(() => import('./features/configurations/config-pages/rating'));
const ConfigMasterComponent = lazy(() => import('./features/configurations/config-pages/optional'));
const ConfigTrailerComponent = lazy(() => import('./features/configurations/config-pages/trailer'));
const ConfigGeneralComponent = lazy(() => import('./features/configurations/config-pages/general'));
const ConfigCarrierComponent = lazy(() => import('./features/configurations/config-pages/carrier'));
const ConfigInvoicesComponent = lazy(() => import('./features/configurations/config-pages/invoice'));
const ConfigPasswordComponent = lazy(() => import('./features/configurations/config-pages/password'));
const ConfigMobileAppComponent = lazy(() => import('./features/configurations/config-pages/mobileapp'));
const ConfigTemplatesComponent = lazy(() => import('./features/configurations/config-pages/templates'));
const ConfigClaimManagementComponent = lazy(() => import('./features/configurations/config-pages/claim'));
const ConfigIntegrationComponent = lazy(() => import('./features/configurations/config-pages/integration'));
const ConfigFleetGeneralComponent = lazy(() => import('./features/configurations/config-pages/fleet-general'));
const ConfigCommunicationComponent = lazy(() => import('./features/configurations/config-pages/communication'));
const ConfigServiceVendorComponent = lazy(() => import('./features/configurations/config-pages/service-vendor'));
const ConfigTaskManagementComponent = lazy(() => import('./features/configurations/config-pages/task-management'));
// Icons and components
const Icons = lazy(() => import('./features/icons'));
const ComponentsStory = lazy(() => import('./features/components-story'));
// // task-management
const TaskManagementBoard = lazy(() => import('./features/task-management/board'));
const TaskManagementReport = lazy(() => import('./features/task-management/report'));
const TaskManagementBoardList = lazy(() => import('./features/task-management/board-list'));
// // claim-management
const ClaimManagementReport = lazy(() => import('./features/claim-management/report'));
const ClaimManagementClaimDetails = lazy(() => import('./features/claim-management/details'));

const delay = (promise: Object) => (
  new Promise((resolve: Object) => setTimeout(resolve, 200)).then(() => promise)
);

const NewDOComponent = lazy(() => delay(import('./features/new-do')));
const CustomerPortal = lazy(() => delay(import('./features/customer-portal')));

export const RouteWithSubRoutes = (route: Object) => (
  <Route
    path={route.path}
    render={(props: Object) => <route.component {...props} routes={route.routes} />}
  />
);

const startMouseFlow = () => {
  window._mfq = window._mfq || [];

  return '//cdn.mouseflow.com/projects/90f2fef0-3549-408a-8856-8f627e775da9.js';
};

const Fallback = () => {
  return <Box height='100vh' bg='mainDarkTransparent' />;
  // return  <MainLayouts />;
};

const AppRoutes = (props: Object) => (
  <div>
    <Suspense fallback={<Fallback />}>
      {
        props.routes.map((route: Object, i: number) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))
      }
    </Suspense>
    <ScriptTag
      type='text/javascript'
      src={G.makeGoogleAPILinkWithKey(props.googleAPIKey)}
    />
    <ScriptTag type='text/javascript' src={startMouseFlow()} />
  </div>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  googleAPIKey: makeSelectGoogleApiKey(state),
}));

const App = connect(mapStateToProps)(AppRoutes);

const fleetRoutes = [
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_DRIVERS_LIST,
  },
  {
    component: EditDriver,
    path: GC.ROUTE_PATH_FLEET_DRIVERS_EDIT,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_TRAILERS_LIST,
  },
  {
    component: TrailerEdit,
    path: GC.ROUTE_PATH_FLEET_TRAILERS_EDIT,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_TRUCKS_LIST,
  },
  {
    component: EditTruck,
    path: GC.ROUTE_PATH_FLEET_TRUCKS_EDIT,
  },
  {
    component: FleetMap,
    path: GC.ROUTE_PATH_FLEET_MAP,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_VENDOR_LIST,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_EQUIPMENT_SERVICE_LIST,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_SHARED_COMPONENT_LIST,
  },
  {
    component: EditFleetVendor,
    path: GC.ROUTE_PATH_FLEET_VENDOR_EDIT,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST,
  },
  {
    component: FleetList,
    path: GC.ROUTE_PATH_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST,
  },
];

const Fleet = () => (
  <ListWrapper p='0' bgColor={G.getTheme('pages.layOutBgColor')}>
    {
      fleetRoutes.map((route: Object, i: number) => <RouteWithSubRoutes key={i} {...route} />)
    }
  </ListWrapper>
);

const configurationsRoutes = [
  {
    component: ConfigPasswordComponent,
    path: GC.ROUTE_PATH_CONFIG_PASSWORD,
  },
  {
    component: ConfigTelComponent,
    path: GC.ROUTE_PATH_CONFIG_TEL,
  },
  {
    component: ConfigCloComponent,
    path: GC.ROUTE_PATH_CONFIG_CLO,
  },
  {
    component: ConfigGeneralComponent,
    path: GC.ROUTE_PATH_CONFIG_GENERAL,
  },
  {
    component: ConfigMasterComponent,
    path: GC.ROUTE_PATH_CONFIG_OPTIONAL,
  },
  {
    component: ConfigTemplatesComponent,
    path: GC.ROUTE_PATH_CONFIG_TEMPLATES,
  },
  {
    component: ConfigDriverComponent,
    path: GC.ROUTE_PATH_CONFIG_DRIVER,
  },
  {
    component: ConfigFleetGeneralComponent,
    path: GC.ROUTE_PATH_CONFIG_FLEET_GENERAL,
  },
  {
    component: ConfigTrailerComponent,
    path: GC.ROUTE_PATH_CONFIG_TRAILER,
  },
  {
    component: ConfigTruckComponent,
    path: GC.ROUTE_PATH_CONFIG_TRUCK,
  },
  {
    component: ConfigInvoicesComponent,
    path: GC.ROUTE_PATH_CONFIG_INVOICES,
  },
  {
    component: ConfigRatingComponent,
    path: GC.ROUTE_PATH_CONFIG_RATING,
  },
  {
    component: ConfigCommunicationComponent,
    path: GC.ROUTE_PATH_CONFIG_COMMUNICATION,
  },
  {
    component: ConfigCarrierComponent,
    path: GC.ROUTE_PATH_CONFIG_CARRIER,
  },
  {
    component: ConfigServiceVendorComponent,
    path: GC.ROUTE_PATH_CONFIG_SERVICE_VENDOR,
  },
  {
    component: ConfigIntegrationComponent,
    path: GC.ROUTE_PATH_CONFIG_INTEGRATION,
  },
  {
    component: ConfigUIComponent,
    path: GC.ROUTE_PATH_CONFIG_UI,
  },
  {
    component: ConfigMobileAppComponent,
    path: GC.ROUTE_PATH_CONFIG_MOBILEAPP,
  },
  {
    component: ConfigAuditPage,
    path: GC.ROUTE_PATH_CONFIG_AUDIT,
  },
  {
    component: ConfigTaskManagementComponent,
    path: GC.ROUTE_PATH_CONFIG_TASK_MANAGEMENT,
  },
  {
    component: ConfigClaimManagementComponent,
    path: GC.ROUTE_PATH_CONFIG_CLAIM_MANAGEMENT,
  },
];

const ConfigurationsComponent = () => (
  <div>
    {
      configurationsRoutes.map((route: Object, i: number) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))
    }
  </div>
);

const invoiceRoutes = [
  {
    component: DriverInvoiceList,
    path: GC.ROUTE_PATH_DRIVER_INVOICES_LIST,
  },
  {
    component: VendorInvoiceList,
    path: GC.ROUTE_PATH_VENDOR_INVOICES_LIST,
  },
  {
    component: CustomerInvoiceList,
    path: GC.ROUTE_PATH_CUSTOMER_INVOICES_LIST,
  },
  {
    component: CarrierInvoiceList,
    path: GC.ROUTE_PATH_CARRIER_INVOICES_LIST,
  },
  {
    component: ServiceVendorInvoiceList,
    path: GC.ROUTE_PATH_SERVICE_VENDOR_INVOICE_LIST,
  },
];

const Invoice = () => (
  <ListWrapper bgColor={G.getTheme('pages.layOutBgColor')}>
    {
      invoiceRoutes.map((route: Object, i: number) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))
    }
  </ListWrapper>
);

const templateRoutes = [
  {
    component: TemplateReport,
    path: GC.ROUTE_PATH_TEMPLATES_DO_LIST,
  },
  {
    component: ContainerTypesComponent,
    path: GC.ROUTE_PATH_CONTAINER_TYPE_LIST,
  },
  {
    component: TemplateReport,
    path: GC.ROUTE_PATH_TEMPLATE_CONTAINER_LIST,
  },
  {
    component: TemplateReport,
    path: GC.ROUTE_PATH_TEMPLATES_ROUTE_LIST,
  },
  {
    component: TemplateReport,
    path: GC.ROUTE_PATH_TEMPLATES_COMPENSATION_LIST,
  },
];

const TemplateComponent = () => (
  <ListWrapper bgColor={G.getTheme('pages.layOutBgColor')}>
    {
      templateRoutes.map((route: Object, i: number) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))
    }
  </ListWrapper>
);

export default [
  {
    component: Login,
    path: GC.ROUTE_PATH_LOGIN,
  },
  {
    component: NALLogin,
    path: GC.ROUTE_PATH_NAL_LOGIN,
  },
  {
    component: NewPassword,
    path: GC.ROUTE_PATH_NEW_PASSWORD,
  },
  {
    component: ResetPassword,
    path: GC.ROUTE_PATH_RESET_PASSWORD,
  },
  {
    component: TermsAndConditions,
    path: GC.ROUTE_PATH_TERMS_AND_CONDITIONS,
  },
  {
    component: PrivacyPolicy,
    path: GC.ROUTE_PATH_PRIVACY_POLICY,
  },
  {
    component: NewPasswordMobile,
    path: GC.ROUTE_PATH_NEW_PASSWORD_MOBILE,
  },
  {
    component: NewPasswordMobileSuccessPage,
    path: GC.ROUTE_PATH_NEW_PASSWORD_MOBILE_SUCCESS_PAGE,
  },
  {
    component: CarrierResponsePage,
    path: GC.ROUTE_PATH_CARRIERS_RESPONSE,
  },
  {
    component: CarrierResponseAccept,
    path: GC.ROUTE_PATH_CARRIERS_ACCEPT,
  },
  {
    component: CarrierResponseDecline,
    path: GC.ROUTE_PATH_CARRIERS_DECLINE,
  },
  {
    component: CarrierResponseAccept,
    path: GC.ROUTE_PATH_ORDER_QUOTE_ACCEPT,
  },
  {
    component: CarrierResponseDecline,
    path: GC.ROUTE_PATH_ORDER_QUOTE_DECLINE,
  },
  {
    component: CarrierResponseAccept,
    path: GC.ROUTE_PATH_CARRIER_QUOTE_ACCEPT,
  },
  {
    component: CarrierResponseDecline,
    path: GC.ROUTE_PATH_CARRIER_QUOTE_DECLINE,
  },
  {
    component: CarrierPortalComponent,
    path: GC.ROUTE_PATH_CARRIERS_ADD_MESSAGE_PAGE,
  },
  {
    component: BoshLosRedirect,
    path: GC.ROUTE_PATH_BOSH_LOS_REDIRECT,
  },
  {
    component: BoshLosCallback,
    path: GC.ROUTE_PATH_BOSH_LOS_CALLBACK,
  },
  {
    exact: true,
    component: App,
    path: GC.ROUTE_PATH_HOME,
    routes: [
      {
        component: MainLayout,
        path: GC.ROUTE_PATH_HOME,
      },
      {
        path: GC.ROUTE_PATH_PROFILE,
        component: UserSettingsComponent,
      },
      {
        component: UserComponent,
        path: GC.ROUTE_PATH_USERS_LIST,
      },
      {
        component: RolesComponent,
        path: GC.ROUTE_PATH_ROLES_LIST,
      },
      {
        component: UserSettingsComponent,
        path: GC.ROUTE_PATH_USERS_SETTINGS,
      },
      {
        component: EditRoleComponent,
        path: GC.ROUTE_PATH_ROLES_EDIT,
      },
      {
        path: GC.ROUTE_PATH_ROLES_NEW,
        component: CreateRoleComponent,
      },
      {
        component: BranchList,
        path: GC.ROUTE_PATH_BRANCH_LIST,
      },
      {
        component: EditBranch,
        path: GC.ROUTE_PATH_BRANCH_EDIT,
      },
      {
        component: TemplateReport,
        path: GC.ROUTE_PATH_ITEMS_LIST,
      },
      {
        path: GC.ROUTE_PATH_SEQUENCES_LIST,
        component: SequencesListComponent,
      },
      {
        component: ReferenceListComponent,
        path: GC.ROUTE_PATH_REFERENCES_LIST,
      },
      {
        component: TemplateReport,
        path: GC.ROUTE_PATH_LOCATIONS_LIST,
      },
      {
        component: Fleet,
        path: GC.ROUTE_PATH_FLEET,
      },
      {
        component: NewDOComponent,
        path: GC.ROUTE_PATH_DO_NEW,
      },
      {
        path: GC.ROUTE_PATH_DO_QUOTE,
        component: NewDOQuoteComponent,
      },
      {
        component: LiteNewDOComponent,
        path: GC.ROUTE_PATH_LITE_DO_NEW,
      },
      {
        component: SplashScreenComponent,
        path: GC.ROUTE_PATH_SPLASH_SCREEN_SETTINGS,
      },
      {
        path: GC.ROUTE_PATH_STYLING,
        component: StylingComponent,
      },
      {
        component: ReportsList,
        path: GC.ROUTE_PATH_REPORTS_LIST,
      },
      {
        path: GC.ROUTE_PATH_QUOTE,
        component: QuotesListComponent,
      },
      {
        component: QuotesListComponent,
        path: GC.ROUTE_PATH_ORDER_QUOTES,
      },
      {
        path: GC.ROUTE_PATH_CONFIG,
        component: ConfigurationsComponent,
      },
      {
        path: GC.ROUTE_PATH_CLO,
        component: OrderDetailsNewComponent,
      },
      {
        path: GC.ROUTE_PATH_TEL,
        component: LoadDetailsNewComponent,
      },
      {
        component: Invoice,
        path: GC.ROUTE_PATH_DRIVER_INVOICES,
      },
      {
        component: TemplateComponent,
        path: GC.ROUTE_PATH_TEMPLATES,
      },
      {
        component: DispatchPlanner,
        path: GC.ROUTE_PATH_ROUTE_BUILDER,
      },
      {
        component: DispatchPlannerEvents,
        path: GC.ROUTE_PATH_LOAD_PLANNER,
      },
      {
        path: GC.ROUTE_PATH_DASHBOARD,
        component: DashboardComponent,
      },
      {
        path: GC.ROUTE_PATH_DASHBOARDS_PAYROLL,
        component: DashboardsPayrollComponent,
      },
      {
        component: OrderListComponent,
        path: GC.ROUTE_PATH_CLO_REPORT_LIST,
      },
      {
        component: LoadListComponent,
        path: GC.ROUTE_PATH_TEL_REPORT_LIST,
      },
      {
        component: RouteListComponent,
        path: GC.ROUTE_PATH_ROUTE_REPORT_LIST,
      },
      {
        component: ChartsListComponent,
        path: GC.ROUTE_PATH_CHARTS_LIST,
      },
      {
        component: ChartFormComponent,
        path: GC.ROUTE_PATH_CHARTS_EDIT,
      },
      {
        component: ChartFormComponent,
        path: GC.ROUTE_PATH_CHARTS_NEW,
      },
      {
        component: CarrierEditComponent,
        path: GC.ROUTE_PATH_CARRIER_EDIT,
      },
      {
        component: CarrierProfile,
        path: GC.ROUTE_PATH_CARRIER_PROFILE,
      },
      {
        component: CarriersListComponent,
        path: GC.ROUTE_PATH_CARRIERS_LIST,
      },
      {
        component: CustomerMasterInvoicesComponent,
        path: GC.ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST,
      },
      {
        component: DriverPayrollsComponent,
        path: GC.ROUTE_PATH_DRIVER_PAYROLL_LIST,
      },
      {
        component: LoadBoard,
        path: GC.ROUTE_PATH_LOAD_BOARD,
      },
      {
        component: NotificationListComponent,
        path: GC.ROUTE_PATH_NOTIFICATIONS,
      },
      {
        component: Imports,
        path: GC.ROUTE_PATH_IMPORT,
      },
      {
        path: GC.ROUTE_PATH_INTL,
        component: IntlComponent,
      },
      {
        path: GC.ROUTE_PATH_FUEL_CARDS,
        component: FuelCardListComponent,
      },
      {
        component: RouteListLoad,
        path: GC.ROUTE_PATH_DISPATCH_BOARD_TEL,
      },
      {
        path: GC.ROUTE_PATH_TOLL_CHARGES,
        component: TollChargesListComponent,
      },
      {
        component: RouteListOrder,
        path: GC.ROUTE_PATH_DISPATCH_BOARD_CLO,
      },
      {
        component: TemplateReport,
        path: GC.ROUTE_PATH_CONTACT_BOOK,
      },
      {
        component: NewDOComponent,
        path: GC.ROUTE_PATH_TEMPLATE_DO_DETAILS,
      },
      {
        component: LiteNewDOComponent,
        path: GC.ROUTE_PATH_LITE_TEMPLATE_DO_DETAILS,
      },
      {
        component: TemplateReport,
        path: GC.ROUTE_PATH_SHARED_ACCESSORIALS,
      },
      {
        component: AverageFuelPriceList,
        path: GC.ROUTE_PATH_AVERAGE_FUEL_PRICE,
      },
      {
        component: CarrierContractList,
        path: GC.ROUTE_PATH_CARRIER_CONTRACTS,
      },
      {
        component: CarrierContractDetails,
        path: GC.ROUTE_PATH_CARRIER_CONTRACT_DETAILS,
      },
      {
        component: CustomerContractList,
        path: GC.ROUTE_PATH_CUSTOMER_CONTRACTS,
      },
      {
        component: RatingGeoFencingZoneList,
        path: GC.ROUTE_PATH_GEO_FENCING_ZONE,
      },
      {
        component: ReportGeoFencingZoneList,
        path: GC.ROUTE_PATH_GEO_FENCING_ZONE_REPORT,
      },
      {
        component: DriversCardComponent,
        path: GC.ROUTE_PATH_DRIVERS_CARD,
      },
      {
        path: GC.ROUTE_PATH_ROUTE_LOAD,
        component: LoadDetailsNewComponent,
      },
      {
        path: GC.ROUTE_PATH_ROUTE_ORDER,
        component: OrderDetailsNewComponent,
      },
      {
        component: IftaListComponent,
        path: GC.ROUTE_PATH_IFTA_REPORT,
      },
      {
        component: DispatchGroupList,
        path: GC.ROUTE_PATH_DISPATCH_GROUP,
      },
      {
        path: GC.ROUTE_PATH_EDI_LIST,
        component: EDIIntegrationReport,
      },
      {
        component: CarrierEdiList,
        path: GC.ROUTE_PATH_CARRIER_EDI_LIST,
      },
      {
        component: PublicCLOList,
        path: GC.ROUTE_PATH_PUBLIC_CLO_LIST,
      },
      {
        component: NormalizeCurrencyList,
        path: GC.ROUTE_PATH_NORMALIZE_CURRENCY_LIST,
      },
      {
        component: CommissionAssignmentList,
        path: GC.ROUTE_PATH_COMMISSION_ASSIGNMENT,
      },
      {
        component: CarrierRatingReportList,
        path: GC.ROUTE_PATH_CARRIER_RATING_REPORT,
      },
      {
        component: CustomerPortal,
        path: GC.ROUTE_PATH_CUSTOMER_PORTAL,
      },
      {
        component: ImportReport,
        path: GC.ROUTE_PATH_IMPORT_REPORT,
      },
      {
        component: ChargeReport,
        path: GC.ROUTE_PATH_CLO_RATE_CHARGE_REPORT,
      },
      {
        component: ChargeReport,
        path: GC.ROUTE_PATH_TEL_RATE_CHARGE_REPORT,
      },
      {
        component: ServiceVendorList,
        path: GC.ROUTE_PATH_SERVICE_VENDOR_LIST,
      },
      {
        component: ServiceVendorProfile,
        path: GC.ROUTE_PATH_SERVICE_VENDOR_EDIT,
      },
      {
        component: RateShop,
        path: GC.ROUTE_PATH_RATE_SHOP,
      },
      {
        component: RouteMassCreateReport,
        path: GC.ROUTE_PATH_ROUTE_MASS_CREATE_REPORT,
      },
      {
        component: LosSubscriptionList,
        path: GC.ROUTE_PATH_LOS_SUBCRIPTION_LIST,
      },
      {
        component: AvailableDriverList,
        path: GC.ROUTE_PATH_AVAILABLE_DRIVER_LIST,
      },
      {
        component: LoadPlannerTemplateList,
        path: GC.ROUTE_PATH_LOAD_PLANNER_TEMPLATE_LIST,
      },
      {
        component: OAuthPopup,
        path: GC.ROUTE_PATH_OAUTH2_POPUP,
      },
      // fleet-profile
      {
        component: FleetProfileDriver,
        path: GC.ROUTE_PATH_FLEET_PROFILE_DRIVER,
      },
      {
        component: FleetProfileVendor,
        path: GC.ROUTE_PATH_FLEET_PROFILE_VENDOR,
      },
      {
        component: FleetProfileTruck,
        path: GC.ROUTE_PATH_FLEET_PROFILE_TRUCK,
      },
      {
        component: FleetProfileTrailer,
        path: GC.ROUTE_PATH_FLEET_PROFILE_TRAILER,
      },
      // task-management
      {
        component: TaskManagementBoard,
        path: GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD,
      },
      {
        component: TaskManagementReport,
        path: GC.ROUTE_PATH_TASK_MANAGEMENT_REPORT,
      },
      {
        component: TaskManagementBoardList,
        path: GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD_LIST,
      },
      // claim-management
      {
        component: ClaimManagementReport,
        path: GC.ROUTE_PATH_CLAIM_MANAGEMENT_LIST,
      },
      {
        component: ClaimManagementClaimDetails,
        path: GC.ROUTE_PATH_CLAIM_MANAGEMENT_CLAIM_DETAILS,
      },
      // Icons and components
      {
        component: Icons,
        path: GC.ROUTE_PATH_DEV_ICONS,
      },
      {
        component: ComponentsStory,
        path: GC.ROUTE_PATH_DEV_COMPONENTS,
      },
    ],
  },
];
